// src/features/roundSlice.js
import { createSlice } from '@reduxjs/toolkit';

const positionListDict = {
  "interview": ["resume interview", "python engineer"],
  "resume": ["resume advice"],
  "skill": ["english practice"],
  "invite": [],
}

export const roundSlice = createSlice({
  name: 'round',
  initialState: {
    value: 0,
    started: false,
    ended: false,
    tab: "interview",
    positionList: ["resume interview", "python engineer"],
    position: "resume interview",
    resumeUrl: "",
    roundCount: 0,
    roundLimit: 3,
    conversationUuid: "",
    interviewerMessageUuid: "",
    // interviewerAudioUrl: "https://interview-bot.oss-cn-hangzhou.aliyuncs.com/voice-message/45c4f68d-d45b-44ce-a625-f24c19c8aa75.mp3",
    interviewerAudioUrl: null,
    // interviewerSubtitle: "您将面试一个python工程师的职位",
    interviewerSubtitle: "",
    candidateSubtitle: "等待您的回答。。",
    score: "待定",
    suggestion: "待定",
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    changeTab: (state, action) => {
      console.log("change tab", action.payload);
      const tab = action.payload.tab;
      const positionList = positionListDict[tab]
      state.tab = tab;
      state.positionList = positionList;
      state.position = positionList && positionList[0];
      state.started = false;
      state.ended = false;
      state.resumeUrl = "";
      state.roundCount = 0;
      state.roundLimit = 3;
      state.interviewerMessageUuid = "";
      state.interviewerAudioUrl = "";
      state.interviewerSubtitle = "";
      state.candidateSubtitle = "等待您的回答。。";
    },
    changePosition: (state, action) => {
      console.log("change position", action.payload);
      state.position = action.payload.position;
    },
    changeRoundLimit: (state, action) => {
      console.log("change round limit", action.payload);
      state.roundLimit = action.payload.roundLimit;
    },
    uploadResume: (state, action) => {
      console.log("upload resume", action.payload);
      state.resumeUrl = action.payload.resumeUrl;
    },
    startInterview: (state, action) => {
      console.log("start interview", action.payload);
      state.started = true;
      state.interviewerMessageUuid = action.payload.interviewerMessageUuid;
      state.interviewerAudioUrl = action.payload.audioUrl;
      state.interviewerSubtitle = action.payload.content;
      state.conversationUuid = action.payload.conversationUuid;
    },
    answerQuestion: (state, action) => {
      console.log("answer question", action.payload);
      state.interviewerMessageUuid = action.payload.interviewerMessageUuid;
      state.interviewerAudioUrl = action.payload.audioUrl;
      state.interviewerSubtitle = action.payload.content;
      state.candidateSubtitle = action.payload.candidateSubtitle;
      if (action.payload.content && action.payload.content.startsWith("您的面试已经结束")) {
        console.log("interview has ended");
        state.ended = true;
      }
    },
    displayFeedback: (state, action) => {
      console.log("display feedback", action.payload);
      state.score = action.payload.score;
      state.suggestion = action.payload.suggestion;
    }
  },
});

// Export actions
export const { increment, decrement, changeTab, changePosition, changeRoundLimit, uploadResume, startInterview, answerQuestion, displayFeedback } = roundSlice.actions;

// Export the reducer
export default roundSlice.reducer;
